import { render, staticRenderFns } from "./crearPropietario.vue?vue&type=template&id=59bf1f0e&scoped=true"
import script from "./crearPropietario.vue?vue&type=script&lang=js"
export * from "./crearPropietario.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59bf1f0e",
  null
  
)

export default component.exports