<template>
    <section v-loading="loading" class="viviendas-crear-residente ml-4">
        <div class="row my-4">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ name: 'propietarios' }">
                        Propietario
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>Nuevo propietario</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-auto">
                <p class="f-20 f-500">Nuevo propietario</p>
            </div>
        </div>
        <ValidationObserver ref="validation">
            <div class="col-6">
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="Nombres" tag="div">
                            <p class="f-12 pl-2 label-inputs">Nombres</p>
                            <el-input v-model="propietario.nombre" placeholder="Escribe tus nombres" show-word-limit
                                      maxlength="20" size="small"
                            />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="Apellidos" tag="div">
                            <p class="f-12 pl-2 label-inputs">Apellidos</p>
                            <el-input v-model="propietario.apellido" placeholder="Escribe sus apellidos" show-word-limit
                                      maxlength="20" size="small"
                            />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required" name="Fecha de Nacimiento" tag="div">
                            <p class="f-12 pl-2 label-inputs">Fecha de Nacimiento</p>
                            <el-date-picker v-model="propietario.nacimiento" placeholder="Fecha de nacimiento"
                                            format="yyyy/MM/dd" value-format="yyyy-MM-dd" type="date" size="small" class="w-100"
                            />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="email" name="Correo" tag="div">
                            <p class="f-12 pl-2 label-inputs">Correo</p>
                            <el-input v-model="propietario.correo" placeholder="Correo electrónico" show-word-limit
                                      maxlength="70" size="small" class="w-100"
                            />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="numeric|max:15" name="Teléfono" tag="div">
                            <p class="f-12 pl-2 label-inputs">Teléfono</p>
                            <el-input v-model="propietario.telefono" placeholder="Teléfono" show-word-limit
                                      maxlength="15" size="small" class="w-100"
                            />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20" name="contraseña" tag="div">
                            <p class="f-12 pl-2 label-inputs">Contraseña</p>
                            <el-input ref="contraseña" v-model="propietario.password" placeholder="Contraseña"
                                      maxlength="20" type="password" size="small" class="w-100"
                            />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required|max:20|confirmed:contraseña"
                                            name="confirmar contraseña" tag="div"
                        >
                            <p class="f-12 pl-2 label-inputs">Confirmar contraseña</p>
                            <el-input v-model="propietario.password_confirmation" placeholder="Confirmar contraseña"
                                      maxlength="20" type="password" size="small" class="w-100"
                            />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <p class="f-12 pl-2 label-inputs">Grupo de viviendas</p>
                        <select-grupos ref="selectViviendasGrupo" @setAgrupacion="setAgrupacion" />
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <p class="f-12 pl-2 label-inputs">Vivienda</p>
                        <ValidationProvider v-slot="{ errors }" rules="required" name="vivienda" tag="div">
                            <el-select v-model="propietario.id_vivienda" placeholder="Seleccionar vivienda" size="small"
                                       class="w-100 mb-3" filterable remote :remote-method="remoteMethod"
                            >
                                <el-option v-for="(vivienda, index) in viviendas" :key="index" :label="vivienda.nombre"
                                           :value="vivienda.id"
                                />
                            </el-select>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="d-flex justify-between">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-6 mb-2">
                        <ValidationProvider v-slot="{ errors }" rules="required" name="Género" tag="div">
                            <p class="f-12 pl-2 label-inputs mb-2">Genero</p>
                            <div class="d-flex f-14 mt-1 my-auto">
                                <label class="d-middle mr-3" for="">
                                    <input v-model="propietario.genero" :value="1" type="radio"
                                           class="option-input opt-gris radio mr-2"
                                    /> Otro
                                </label>
                                <label class="d-middle mr-3" for="">
                                    <input v-model="propietario.genero" :value="2" type="radio"
                                           class="option-input opt-gris radio mr-2"
                                    /> Hombre
                                </label>
                                <label class="d-middle mr-3" for="">
                                    <input v-model="propietario.genero" :value="3" type="radio"
                                           class="option-input opt-gris radio mr-2"
                                    /> Mujer
                                </label>
                            </div>
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                </div>
            </div>

            <div class="row mx-0 mt-4">
                <div class="col-12">
                    <button class="btn btn-general py-1 f-14 f-300 px-3" @click="guardar">
                        Guardar
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>
<script>
import Viviendas from '~/services/viviendas'
import Propietarios from '~/services/propietarios'
export default {
    data(){
        return {
            loading: false,
            propietario: {
                nombre: '',
                apellido: '',
                nacimiento: '',
                id_vivienda: null,
                genero: 1,
                arriendo: 1,
                password: null,
                password_confirmation: null,
                correo: '',
                telefono: null,
                loading: false
            },
            idGrupo: null,
            viviendas: []
        }
    },
    mounted(){
        this.getViviendas()
    },
    methods: {
        setAgrupacion(id){
            if (id != this.idGrupo) this.propietario.id_vivienda = null
            this.getViviendas(id)
            this.idGrupo = id
        },
        async guardar(){
            try {

                let valid = await this.$refs.validation.validate()

                if (!valid) return this.notificacion('', 'Campos incompletos', 'warning')

                if (!this.propietario.correo && !this.propietario.telefono) return this.notificacion('', 'Alguno de los campos de teléfono o correo debe ser llenado')

                let payload = {
                    id_usuario: this.propietario.id_usuario,
                    nombre: this.propietario.nombre,
                    apellido: this.propietario.apellido,
                    fechaNacimiento: this.propietario.nacimiento,
                    email: this.propietario.correo,
                    telefono: this.propietario.telefono,
                    idVivienda: this.propietario.id_vivienda,
                    genero: this.propietario.genero,
                    arriendo: this.propietario.arriendo,
                    password: null,
                }

                if (this.propietario.password != "*******") payload.contrasena = this.propietario.password

                Object.keys(payload).forEach((key) => {
                    if (!payload[key]) delete payload[key];
                })

                if (valid){
                    this.loading = true
                    const { data } = await Propietarios.postPropietario(payload)
                    if (data.success){
                        this.notificacion('Guardar propietario', data.mensaje, 'success');
                        this.$router.push({ name: 'propietarios' })
                    } else {
                        this.notificacion('Guardar propietario', data.mensaje, 'warning');
                    }
                    this.loading = false
                }

            } catch (e){
                this.loading = false
                return this.errorCatch(e)
            }
        },
        async getViviendas(idPadre, search = null){
            try {
                let params = {
                    id_padre: idPadre,
                    search: search
                }
                const { data } = await Viviendas.hijas(params)
                this.viviendas = data.data
            } catch (e){
                this.errorCatch(e)
            }
        },
        remoteMethod(search){
            setTimeout(() => {
                this.getViviendas(this.idGrupo, search)
            }, 100);
        },

    },

}
</script>
<style lang="scss" scoped>

</style>